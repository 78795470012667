<template>
<div>
   <form  class="">
   <div class="row">
      <div class="col-12">
         <div class="card">
            <div class="card-body">
               <div class="row g-3">
                  <div class="col-12" >
                     <h5 class="mb-2">GET STARTED</h5>
                  </div>
                  <div class="col-md-12">
                     <label class="form-label">Store location</label>
                     <select v-model="data.store_id" name="store_id"
                     class="form-select" :class="{ 'is-invalid': v$.data.store_id.$error  }">
                        <option value="" disabled selected>--Select Store--</option>
                        <option
                           v-for="(store, tIndex) in storeList"
                           :value="store.id"
                           :key="tIndex" > {{store.title}} </option>
                     </select>
                     <div v-for="(item, index) in v$.data.store_id.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-12 col-sm-6" >
                     <label class="form-label" for="first_name">First name</label>
                     <input v-model="data.first_name" class="form-control" id="first_name"
                        type="text"  placeholder="First name" name="first_name" :class="{ 'is-invalid': v$.data.first_name.$error }">
                     <div v-for="(item, index) in v$.data.first_name.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-12 col-sm-6" >
                     <label class="form-label" for="last_name">Last name</label>
                     <input v-model="data.last_name" class="form-control" id="last_name"
                        type="text"  placeholder="Last name" name="last_name" :class="{ 'is-invalid': v$.data.last_name.$error }">
                     <div v-for="(item, index) in v$.data.last_name.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6" >
                     <label class="form-label" for="ssn_itin">SSN/ITIN</label>
                     <input v-maska data-maska="###-##-####" v-model="data.ssn" class="form-control" id="ssn_itin" type="text"
                        placeholder="xxx-xx-xxxx" :class="{ 'is-invalid': v$.data.ssn.$error }" >
                     <div v-for="(item, index) in v$.data.ssn.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6" >
                     <label class="form-label" for="confirm_ssn_itin">Confirm SSN/ITIN</label>
                     <input v-maska data-maska="###-##-####" v-model="data.confirm_ssn" class="form-control" id="confirm_ssn_itin" type="text"
                        placeholder="xxx-xx-xxxx" :class="{ 'is-invalid': v$.data.confirm_ssn.$error }" >
                     
                     <div v-for="(item, index) in v$.data.confirm_ssn.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-lg-8" >
                     <label class="form-label" for="email">Email</label>
                     <input v-model="data.email" class="form-control" :class="{ 'is-invalid': v$.data.email.$error }"
                     id="email" type="email"  placeholder="Email" name="email" >
                     <div v-for="(item, index) in v$.data.email.$errors" 
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-lg-4" >
                     <label class="form-label" for="mobile">Mobile phone</label>
                     <input v-maska data-maska="(###) ###-####" v-model="data.mobile" id="mobile" class="form-control" type="tel"
                        placeholder="Mobile phone" :class="{ 'is-invalid': v$.data.mobile.$error }" >
                     <div v-for="(item, index) in v$.data.mobile.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-12" >
                     <small>By providing your email and phone number, you consent to receiving mails, autodialed texts and calls from Moriso Wireless for non-telemarketing purposes regarding account information, such as transaction details or servicing information. 
                        You are agreeing to the <a :href="absoluteUrl('/privacy-policy')" target="_blank"> Privacy Policy</a> and <a :href="absoluteUrl('/partners/terms-conditions')" target="_blank"> Terms of Service</a>. Message frequency varies, and messaging and data rates may apply.</small>
                  </div>
               </div>
            </div>
         </div>
         <div class="card">
            <div class="card-body">
               <div class="row g-3">        
                  <div class="col-12" >
                     <h5 class="mb-2">APPLICANT INFORMATION</h5>
                  </div>
                  <div class="col-12">
                     <label class="form-label" for="address">Street address</label>
                     <input v-model="data.address" id="address" class="form-control" type="text"
                        placeholder="Street address" :class="{ 'is-invalid': v$.data.address.$error }" >
                     <div v-for="(item, index) in v$.data.address.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6">
                     <label class="form-label" for="city">City</label>
                     <input v-model="data.city" id="city" class="form-control" type="text"
                        placeholder="City" :class="{ 'is-invalid': v$.data.city.$error }" >
                     <div v-for="(item, index) in v$.data.city.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6">
                     <label class="form-label" for="state">State</label>
                     <input v-model="data.state" id="state" class="form-control" type="text"
                        placeholder="State" :class="{ 'is-invalid': v$.data.state.$error }" >
                     <div v-for="(item, index) in v$.data.state.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                   <div class="col-sm-6 col-md-4">
                     <label class="form-label" for="zip_code">Zip code</label>
                     <input v-model="data.zip_code" id="zip_code" class="form-control" type="number"
                        placeholder="Zip code" :class="{ 'is-invalid': v$.data.zip_code.$error }" >
                     <div v-for="(item, index) in v$.data.zip_code.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-md-4">
                     <label class="form-label" for="apt">Apt #</label>
                     <input v-model="data.apt" id="apt" class="form-control" type="text"
                        placeholder="Apt" >
                  </div>
                  <div class="col-sm-6 col-md-4">
                     <label class="form-label">Preferred language</label>
                     <select v-model="data.language" name="language"
                     class="form-select">
                        <option value="" disabled selected>--language--</option>
                        <option value="english"> English </option>
                        <option value="creole"> Creole </option>
                        <option value="spanish"> Spanish </option>
                        <option value="french"> French </option>
                     </select> 
                  </div>
               </div>
            </div>
         </div>
         <div class="card">
            <div class="card-body">
               <div class="row g-3">        
                  <div class="col-12" >
                     <h5 class="mb-2">APPLICANT IDENTITY VERIFICATION</h5>
                  </div>
                  <div class="col-sm-6 col-md-7">
                     <label class="form-label" for="address">Date of birth</label>
                     <flat-pickr
                        v-model="data.dob"
                        :config="{wrap: true, 
                           altFormat: 'M j, Y',
                           altInput: true,
                           dateFormat: 'd-m-Y',
                           maxDate: new Date()
                        }"
                        class="form-control flatpickr-input"
                     ></flat-pickr> 
                  </div>
                  <div class="col-sm-6 col-md-5">
                     <label class="form-label">Gender</label>
                     <select v-model="data.gender" name="gender"
                     class="form-select" :class="{ 'is-invalid': v$.data.gender.$error }">
                        <option disabled value="">--gender--</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="others"> Others </option>
                     </select>
                     <div v-for="(item, index) in v$.data.gender.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-md-4">
                     <label class="form-label">ID type</label>
                     <select v-model="data.id_type" name="id_type"
                     class="form-select" :class="{ 'is-invalid': v$.data.id_type.$error }">
                        <option disabled value="">--ID type--</option>
                        <option value="Driver's license"> Driver's license </option>
                        <option value="State ID"> State ID </option>
                        <option value="Millitary ID"> Millitary ID </option>
                        <option value="U.S. Passport"> U.S. Passport </option>
                        <option value="Resident Alien Card"> Resident Alien Card </option>
                        <option value="Matricula Card"> Matricula Card </option>
                           <option v-for="type in idTypes" :key="type.value" :value="type.value">{{ type.label }}</option>
                     </select> 
                     <div v-for="(item, index) in v$.data.id_type.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-md-5">
                     <label class="form-label" for="id_number">ID number</label>
                     <input v-model="data.id_number" id="id_number" class="form-control" type="text"
                        placeholder="ID number" :class="{ 'is-invalid': v$.data.id_number.$error }" >
                     <div v-for="(item, index) in v$.data.id_number.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-md-3">
                     <label class="form-label" for="id_state">ID state</label>
                     <input v-model="data.id_state" id="id_state" class="form-control" type="text"
                        placeholder="ID State" :class="{ 'is-invalid': v$.data.id_state.$error }" >
                     <div v-for="(item, index) in v$.data.id_state.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
               </div>
            </div>
         </div>
         <div class="card">
            <div class="card-body">
               <div class="row g-3">        
                  <div class="col-12" >
                     <h5 class="mb-2">APPLICANT BANK ACCOUNT</h5>
                  </div>
                  <div class="col-sm-7">
                     <label class="form-label" for="account_holder_name">Account holder name</label>
                     <input id="account_holder_name" type="text" class="form-control" v-model="data.account_holder_name"
                     :class="{ 'is-invalid': v$.data.account_holder_name.$error }"  placeholder="Account holder name" name="account_holder_name" />
                     <div v-for="(item, index) in v$.data.account_holder_name.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-5">
                     <label class="form-label" for="account_number">Account number</label>
                     <input id="account_number" type="text" class="form-control" v-model="data.account_number"
                     :class="{ 'is-invalid': v$.data.account_number.$error }"  placeholder="Account number" name="account_number" />
                     <div v-for="(item, index) in v$.data.account_number.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-md-4">
                     <label class="form-label" for="bank_name">Bank name</label>
                     <input id="bank_name" type="text" class="form-control" v-model="data.bank_name"
                     :class="{ 'is-invalid': v$.data.bank_name.$error }"  placeholder="Bank name" name="bank_name" />
                     <div v-for="(item, index) in v$.data.bank_name.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-md-4">
                     <label class="form-label" for="routing_number">Routing number</label>
                     <input id="routing_number" type="text" class="form-control" v-model="data.routing_number"
                     :class="{ 'is-invalid': v$.data.routing_number.$error }"  placeholder="Routing number" name="routing_number" />
                     <div v-for="(item, index) in v$.data.routing_number.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-md-4">
                     <label class="form-label" for="account_holder_type">Account holder type</label>
                     <select v-model="data.account_holder_type" name="account_holder_type" id="account_holder_type"
                     class="form-select" :class="{ 'is-invalid': v$.data.account_holder_type.$error }">
                        <option disabled value="" >--type--</option>
                        <option value="individual">Individual</option>
                        <option value="company">Company</option>
                     </select>
                     <div v-for="(item, index) in v$.data.account_holder_type.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div>  
                  </div>
               </div>
            </div>
         </div>
         <div class="card">
            <div class="card-body">
               <div class="row g-3">        
                  <div class="col-12" >
                     <h5 class="mb-2">APPLICANT PRIMARY INCOME</h5>
                  </div>
                  <div class="col-lg-4">
                     <label class="form-label">Income source</label>
                     <select v-model="data.income_source" name="income_source"
                     class="form-select" :class="{ 'is-invalid': v$.data.income_source.$error }">
                        <option disabled value="">--Income source--</option>
                        <option v-for="source in incomeSources" :key="source.value" :value="source.value">{{ source.label }}</option>
                     </select> 
                     <div v-for="(item, index) in v$.data.income_source.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-lg-4">
                     <label class="form-label">How are you paid?</label>
                     <select v-model="data.income_payment_mode" name="income_payment_mode"
                     class="form-select" :class="{ 'is-invalid': v$.data.income_payment_mode.$error }">
                        <option disabled value="">--payment mode--</option>
                        <option v-for="option in paymentOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
                     </select>
                     <div v-for="(item, index) in v$.data.income_payment_mode.$errors"
                        :key="index" class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
                  <div class="col-sm-6 col-lg-4">
                     <label class="form-label" for="monthly_net">Monthly income</label>
                     <div class="input-group">
                        <span class="input-group-text">$</span>
                        <input v-model="data.monthly_net" id="monthly_net" class="form-control" type="number"
                        placeholder="Monthly income" :class="{ 'is-invalid': v$.data.monthly_net.$error }" >
                        <span class="input-group-text">.00</span>
                     </div>
                     <div v-for="(item, index) in v$.data.monthly_net.$errors"
                        :key="index" class="text-danger">
                        <span v-if="item.$message">{{ item.$message }}</span>
                     </div> 
                  </div>
               </div>
            </div>
         </div>
         <div class="">
            <div class="form-check mb-3">
               <input v-model="data.terms_agreement" class="form-check-input" type="checkbox" value="" id="terms-agreement">
               <label class="form-check-label small" for="terms-agreement">
                  I understand that by clicking on the "SUBMIT" button immediately following this notice,
                   I am providing written instructions to Moriso Wireless under the Fair Credit Reporting Act authorizing
                   Moriso Wireless to obtain information about me from credit reporting agencies 
                   (including but not limited to Experian, Clarity, Equifax, and TransUnion) or others, 
                   including my creditors and other third parties: (1) in connection with this transaction for the purpose
                    of evaluating my rental purchase application, to confirm my identity, and to avoid fraudulent transactions; 
                    and (2) at any time and use that information for the purposes of (a) considering me for new offers, programs,
                  and services that Moriso Wireless, its affiliates, or third parties may offer in the future, and (b) for marketing
                  such programs to me. I also understand that by clicking "SUBMIT" I agree to the APPLICATION TERMS AND ARBITRATION AGREEMENT,
                  PRIVACY POLICY, TERMS OF SERVICE, AND ESIGN DISCLOSURE.
               </label>
            </div>
            <div class="form-check">
               <input v-model="data.newsletter_agreement" class="form-check-input" type="checkbox" value="" id="newsletter-agreement">
               <label class="form-check-label small" for="newsletter-agreement">
                  I Consent: By checking this box, you agree Moriso Wireless and its agents may deliver telemarketing calls and text messages
                   regarding Moriso Wireless goods, services or offers to you at the telephone number provided on this application using an automated system for the
                  selection or dialing of telephone numbers. You understand that you are not required to consent to marketing calls and text messages in
                  order to obtain goods or services from Moriso Wireless or open an Moriso Wireless account. If you do not consent, Moriso Wireless may still send you text messages 
                  regarding important account information, such as transaction details or servicing information. By checking this box you certify that
                  you are authorized to provide this consent.
               </label>
            </div>
         </div>
      </div>
   </div>
   <div class="row mb-5 mt-4">
      <div class="col-12">
            <div class="">
               <b-button variant="primary"  class="me-3"
               @click.prevent="create()"
               type="button">Submit Application</b-button>
               <b-button variant="outline-dark" class=""
                  @click.prevent="resetForm()"
                  type="button">Cancel</b-button>
            </div>
      </div>
   </div>
   </form>
</div>
</template>

<script>
import { required, email, numeric, minValue, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";

export default {
   components: { flatPickr },
   setup() {
      return { v$: useVuelidate() };
   },
   data(){
      return{
         isLoading: false,
         data: {
            store_id: "",
            first_name: "",
            last_name: "",
            ssn: "",
            confirm_ssn: "",
            mobile: "",

            address: "",
            apt: "",
            city: "",
            state: "",
            zip_code: "",
            email: "",
            language: "",

            dob: "",
            gender: "",
            id_type: "",
            id_number: "",
            id_state: "",

            account_holder_name: "",
            account_holder_type: "",
            bank_name: "",
            routing_number: "",
            account_number: "",

            income_source: "",
            income_payment_mode: "",
            monthly_net: "",

            terms_agreement: false,
            newsletter_agreement: false
         },
         popupPaymentMethod: false,
         idTypes: [
            { label: "Driver's license", value: "drivers-license" },
            { label: "State ID", value: "state-id" },
            { label: "Military ID", value: "military-id" },
            { label: "U.S. Passport", value: "us-passport" },
            { label: "Resident Alien Card", value: "resident-alien-card" },
            { label: "Matricula Card", value: "matricula-card" }
         ],
         incomeSources: [
            { value: 'full_time_job', label: 'Full-Time Job' },
            { value: 'part_time_job', label: 'Part-Time Job' },
            { value: 'self_employment', label: 'Self-Employment' },
            { value: 'social_security', label: 'Social Security' },
            { value: 'retirement', label: 'Retirement' },
            { value: 'military', label: 'Military' },
            { value: 'long_term_disability', label: 'Long Term Disability' },
            { value: 'alimony', label: 'Alimony' }
         ],
         paymentOptions: [
            { value: 'direct_deposit', label: 'Direct Deposit' },
            { value: 'printed_check', label: 'Printed Check' }
         ],
      }
   },
   validations() {
      return {
         data:{
            store_id: {
               required: required,
            },
            first_name: {
               required: required,
            },
            last_name: {
               required: required,
            },
            ssn: {
               required: required,
               validSSN: function(value) {
                  const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
                  return ssnPattern.test(value);
               }
            },
            confirm_ssn: {
               required: required,
               sameAsSSN: function(value) {
                  return value === this.data.ssn;
               }
            },
            mobile: {
               required: required
            },
            address: {
               required: required,
            },
            city: {
               required: required,
            },
            state: {
               required: required,
            },
            zip_code: {
               required: required
            },
            email: {
               required: required,
               email: helpers.withMessage("Please enter a valid email", email),
            },
            dob: {
               required: required,
            },
            gender: {
               required: required,
            },
            id_type: {
               required: required,
            },
            id_number: {
               required: required,
            },
            id_state: {
               required: required,
            },

            account_holder_name:{
               required: required,
            },
            account_holder_type: {
               required: required,
            },
            bank_name: {
               required: required,
            },
            routing_number: {
               required: required,
            },
            account_number: {
               required: required,
            },

            income_source: {
               required: required,
            },
            income_payment_mode:{
               required: required,
            },
            monthly_net:  {
               required, numeric,
               minValue: minValue(750)
            }
         }
      }
    },
    computed:{
      storeList(){
         return this.$store.state.store.list
      },
    },
   methods:{
      create() {
         this.v$.$validate().then(result =>{
            if (!result) {this.alertError('Form is not valid'); return;}
            if(!this.data.terms_agreement){this.alertError('You must agree to our terms and conditions'); return;}

            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post('/contracts/create', formData)
            .then((response) => {
               this.$store.dispatch("changeLoaderValue", false)
               if(response.data.success){
                  this.resetForm();
                  this.$router.push({path: `/leases/${response.data.data.id}`})
               }
            })
         })
      },
      resetForm(){
         this.v$.$reset()
      }
    },
}
</script>