<template>
  <div>
    <div class="col-12 mb-4">
      <h5 class="mb-3">Lease Application</h5>
    </div>
    <lease-form />
  </div>
</template>

<script>
import LeaseForm from '@/components/forms/LeaseForm.vue'
export default {
  components: { LeaseForm },
  created(){
    this.$store.dispatch("store/fetchStores")
  }
}
</script>

<style>

</style>